import React from 'react';
import '../App.css';
import logo from '../assets/logo.png';

function HomeScreen() {
  return (
    <div className="HomeScreen">
      <header className="HomeScreen-header">
        <img src={logo} className="HomeScreen-logo" alt="logo" />
        <nav className="HomeScreen-nav">
          <button className="HomeScreen-button">Txty</button>
          <button className="HomeScreen-button">Padster</button>
          <button className="HomeScreen-button">Cwav</button>
          <button className="HomeScreen-button">Projectly</button>
          <button className="HomeScreen-button">Infrablade</button>
        </nav>
      </header>
      <main className="HomeScreen-content">
        <h1>About Sody</h1>
        <p>
          Sody.io is the centralised location for all my current projects.
        </p>
        <p>
          Everything you need to know about the applications can be found at each tab.
        </p>
        <p>
          If you have any questions, please email: hello@sody.io
          </p>
      </main>
      <footer className="HomeScreen-footer">
        &copy; 2024 Sody Limited
      </footer>
    </div>
  );
}

export default HomeScreen;